import React from 'react'
import QuizButton from '../quizButton'
import './styles.scss'

export default function QuestionContainer({ questionId, title, options, selectedAnswer }) {
  return (
    <>
      <div id="question-container">
        <div className="question-title">
          <span>{questionId}</span>
          <h2>{title}</h2>
        </div>

        <div className="options-container">
          {options.map((option, index) => {
            return (
              <QuizButton
                key={index}
                answerId={option.answerId}
                questionId={questionId}
                text={option.text}
                selected={option.answerId === selectedAnswer}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}
