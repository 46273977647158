import React, { useContext } from 'react'
import { useAnswersContext } from '../../components/answersContext'
import './styles.scss'

export default function QuizButton({ text, answerId, questionId, selected }) {
  const [flash, setFlash] = React.useState(false)
  const { recordAnswer } = useAnswersContext()
  const handleClick = () => {
    setFlash(true)
    setTimeout(() => {
      recordAnswer(questionId, answerId)
      setFlash(false)
    }, 500)
  }

  return (
    <button
      className={`quiz-button  ${selected && 'quiz-button-selected'} ${flash && 'quiz-button-flash'}`}
      onClick={handleClick}
    >
      {text}
    </button>
  )
}
