import React, { useEffect, useState } from 'react'
import { default as questionsEn } from '../../assets/questions-en.json'
import { default as questionsPt } from '../../assets/questions-pt.json'
import { useAnswersContext } from '../../components/answersContext'
import CenteredBox from '../../components/centeredBox'
import coverEn from '../../img/cover-en.jpg'
import coverPt from '../../img/cover-pt.jpg'
import './styles.scss'

export default function QuizResults({ lang = 'pt' }) {
  const { answers } = useAnswersContext()
  const [score, setScore] = useState()
  //console.log(answers)

  const questions = lang === 'en' ? questionsEn : questionsPt

  useEffect(() => {
    calculateScore()
  }, [])

  const calculateScore = () => {
    var score = 0

    answers.forEach(answer => {
      const question = questions.find(question => question.questionId === answer.questionId)
      score += question.options.find(option => option.answerId === answer.answerId).score
    })

    setScore(score)
    console.log(score)
  }

  const getResultForScoreEn = () => {
    if (score < 20) {
      return (
        <>
          <h3>You're likely not experiencing abuse</h3>
          <h4>What does this mean?</h4>
          <p>
            Your answers suggests that you’re not experiencing either an emotionally or physically abusive relationship.
          </p>
          <p>
            You experience the expected variations within a relationship that mark most people's intimate, social
            interactions with one another over a period of time.
          </p>
          <p>
            Remember that some conflict can be healthy and are a natural part of relationships. Conflict can become
            harmful when it escalates and becomes aggressive, unkind, and disrespectful.
          </p>
          <p>If you’re in a domestic violence situation, knowing the signs can help you navigate the situation.</p>
          <p>
            Even if it’s not a domestic violence situation, but you’re having trouble with conflict resolution in your
            relationship, consider reaching out to a mental health professional.
          </p>
        </>
      )
    } else if (score < 35) {
      return (
        <>
          <h3>Your relationship shows some signs of abuse</h3>
          <h4>What does this mean?</h4>
          <p>
            Your answers suggests that you may be involved in a relationship that could be considered abusive —
            emotionally, physically, or both. The results of this quiz may also mean there are some toxic elements of
            your relationship you may want to address.
          </p>

          <p>
            Remember that some conflict can be healthy and are a natural part of relationships. Conflict can become
            harmful when it escalates and becomes aggressive, unkind, and disrespectful.
          </p>

          <p>
            If you’re in a domestic violence situation, knowing the signs can help you navigate the situation. If you're
            concerned and want more information, consider reaching out to a mental health professional.
          </p>
        </>
      )
    } else {
      return (
        <>
          <h3>Your relationship shows signs of abuse</h3>
          <h4>What does this mean?</h4>
          <p>Your answers suggests you are likely involved in an abusive relationship.</p>

          <p>
            Remember that some conflict can be healthy and are a natural part of relationships. Conflict can become
            harmful when it escalates and becomes aggressive, unkind, and disrespectful.
          </p>

          <p>If you’re in a domestic violence situation, knowing how to navigate the situation safely is crucial.</p>

          <p>
            When you’re ready, consider reaching out to a mental health professional or social services about next
            steps. You’re not alone and help is available.
          </p>
        </>
      )
    }
  }

  const getResultForScorePt = () => {
    if (score < 20) {
      return (
        <>
          <h3>Você provavelmente não está sofrendo abuso</h3>
          <h4>O que este resultado significa?</h4>
          <p>
            Suas respostas sugerem que você não está vivenciando um relacionamento emocional ou fisicamente abusivo.
          </p>
          <p>
            Você experimenta as variações esperadas em um relacionamento que marcam as interações sociais íntimas da
            maioria das pessoas durante um período de tempo.
          </p>
          <p>
            Lembre-se de que alguns conflitos podem ser saudáveis e fazem parte natural dos relacionamentos. O conflito
            pode tornar-se prejudicial quando aumenta e se torna agressivo, cruel e desrespeitoso.
          </p>
          <p>
            Se você estiver em uma situação de violência doméstica, conhecer os sinais pode ajudá-la a navegar pela
            situação.
          </p>
          <p>
            Mesmo que não seja uma situação de violência doméstica, mas você esteja tendo problemas com a resolução de
            conflitos na sua relacionamento, considere entrar em contato com um profissional de saúde mental.
          </p>
        </>
      )
    } else if (score < 35) {
      return (
        <>
          <h3>Seu relacionamento mostra alguns sinais de abuso</h3>
          <h4>O que este resultado significa?</h4>
          <p>
            Suas respostas sugerem que você pode estar envolvido em um relacionamento que pode ser considerado abusivo.
            emocionalmente, fisicamente ou ambos. Os resultados deste questionário também podem significar que existem
            alguns elementos tóxicos de seu relacionamento você pode querer abordar.
          </p>

          <p>
            Lembre-se de que alguns conflitos podem ser saudáveis e fazem parte natural dos relacionamentos. O conflito
            pode tornar-se prejudicial quando aumenta e se torna agressivo, cruel e desrespeitoso.
          </p>

          <p>
            Se você estiver em uma situação de violência doméstica, conhecer os sinais pode ajudá-lo a navegar pela
            situação. Se vocês são preocupado e deseja mais informações, considere entrar em contato com um profissional
            de saúde mental.
          </p>
        </>
      )
    } else {
      return (
        <>
          <h3>Seu relacionamento mostra sinais de abuso</h3>
          <h4>O que este resultado significa?</h4>
          <p>Suas respostas sugerem que você provavelmente está envolvido em um relacionamento abusivo.</p>

          <p>
            Lembre-se de que alguns conflitos podem ser saudáveis e fazem parte natural dos relacionamentos. O conflito
            pode tornar-se prejudicial quando aumenta e se torna agressivo, cruel e desrespeitoso.
          </p>

          <p>
            Se você estiver em uma situação de violência doméstica, saber como lidar com a situação com segurança é
            crucial.
          </p>

          <p>
            Quando estiver pronto, considere entrar em contato com um profissional de saúde mental ou serviço social
            sobre o próximo passos. Você não está sozinho e a ajuda está disponível.
          </p>
        </>
      )
    }
  }

  const promoBoxPt = () => {
    return (
      <>
        <h3>Descubra a força para romper o ciclo do abuso.</h3>
        <div className="promo-box-top">
          <img src={coverPt} alt="Livro Cicatrizes Ocultas" />

          <div className="promo-box-text">
            <p>
              Independentemente do seu resultado neste Quiz, é possível que você já tenha passado por situações
              semelhantes, esteja enfrentando essa realidade ou conheça alguém que precisa de ajuda.
            </p>{' '}
            <p>
              Não importa em qual dessas categorias você se encaixe, este livro pode oferecer orientações valiosas e
              apoio.
            </p>{' '}
          </div>
        </div>
        <a href="https://cicatrizesocultas.com" target="_blank" rel="noreferrer" className="promo-box-link">
          Compre Agora e Comece Sua Jornada de Cura!
        </a>
      </>
    )
  }

  const promoBoxEn = () => {
    return (
      <>
        <h3>Discover the strength to break the cycle of abuse.</h3>
        <div className="promo-box-top">
          <img src={coverEn} alt="Livro Cicatrizes Ocultas" />

          <div className="promo-box-text">
            <p>
              Regardless of your result in this Quiz, it is possible that you have already experienced situations
              similar, are facing this reality or know someone who needs help.
            </p>{' '}
            <p>No matter which of these categories you fall into, this book can offer valuable guidance and support.</p>{' '}
          </div>
        </div>
        <a href="https://hiddenscars.blog" target="_blank" rel="noreferrer" className="promo-box-link">
          Buy Now and Start Your Healing Journey!
        </a>
      </>
    )
  }

  return (
    <>
      <CenteredBox>
        <div id="results-main">
          <h1>{lang === 'en' ? 'Results' : 'Resultado'}</h1>

          <div className="results-content">{lang === 'en' ? getResultForScoreEn() : getResultForScorePt()}</div>
        </div>
      </CenteredBox>

      <div id="results-promo-box">
        <div className="promo-box-content">
          {lang === 'en' ? promoBoxEn() : promoBoxPt()} {}
        </div>
      </div>
    </>
  )
}
