import React from 'react'
import { AnswersContextProvider } from '../answersContext'
import './styles.scss'

export default function MainContainer({ children }) {
  return (
    <div id="main-container">
      <AnswersContextProvider>
        <div className="main-content">{children}</div>
      </AnswersContextProvider>
    </div>
  )
}
