import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainContainer from './components/mainContainer'
import './index.scss'
import QuizQuestions from './pages/quizQuestions'
import QuizResults from './pages/quizResults'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <MainContainer>
      <BrowserRouter>
        <Routes>
          <Route index element={<QuizQuestions />} />
          <Route path="quiz/pt" element={<QuizQuestions lang="pt" />} />
          <Route path="quiz/en" element={<QuizQuestions lang="en" />} />
          <Route path="quizResults/pt" element={<QuizResults lang="pt" />} />
          <Route path="quizResults/en" element={<QuizResults lang="en" />} />
        </Routes>
      </BrowserRouter>
    </MainContainer>
  </React.StrictMode>
)
