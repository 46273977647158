import React, { createContext, useContext, useState } from 'react'

const AnswersContext = createContext()

export const AnswersContextProvider = ({ children }) => {
  const [answers, setAnswers] = useState([])

  const recordAnswer = (questionId, answerId) => {
    setAnswers(prevAnswers => {
      const newAnswers = prevAnswers.filter(answer => answer.questionId !== questionId)
      newAnswers.push({ questionId, answerId })
      return newAnswers
    })
  }

  return <AnswersContext.Provider value={{ answers, recordAnswer }}>{children}</AnswersContext.Provider>
}

export const useAnswersContext = () => {
  const context = useContext(AnswersContext)
  if (!context) {
    throw new Error('useAnswers must be used within an AnswersContextProvider')
  }
  return context
}
