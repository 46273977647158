import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { default as questionsEn } from '../../assets/questions-en.json'
import { default as questionsPt } from '../../assets/questions-pt.json'
import { useAnswersContext } from '../../components/answersContext'
import CenteredBox from '../../components/centeredBox'
import QuestionContainer from '../../components/questionContainer'
import arrow from '../../img/arrow.svg'
import './styles.scss'

export default function QuizQuestions({ lang = 'pt' }) {
  const [curr, setCurr] = React.useState(0)
  const { answers } = useAnswersContext()
  const navigate = useNavigate()

  const questions = lang === 'en' ? questionsEn : questionsPt

  useEffect(() => {
    if (answers.length === questions.length) {
      navigate(`/quizResults/${lang}`)
    } else if (answers.length === 0) {
      setCurr(0)
    } else {
      document.querySelector('#question-fade-container').classList.add('fade-out')
      setTimeout(() => {
        document.querySelector('#question-fade-container').classList.remove('fade-out')
        goToNextQuestion()
      }, 500)
    }
  }, [answers])

  const goToNextQuestion = () => {
    if (curr < questions.length - 1) {
      setCurr(curr + 1)
    }
  }

  const getAnswer = questionId => {
    const answer = answers.find(answer => answer.questionId === questionId)
    return answer ? answer.answerId : null
  }

  return (
    <CenteredBox>
      <div id="questions-main">
        <div className="questions-progress-bar" style={{ '--current': curr, '--total': questions.length - 1 }}></div>

        <div className="questions-content">
          <section>
            <div id="question-fade-container">
              <QuestionContainer
                questionId={questions[curr].questionId}
                title={questions[curr].title}
                options={questions[curr].options}
                selectedAnswer={getAnswer(questions[curr].questionId)}
              />
            </div>
          </section>
          <nav className="questions-nav-container">
            <button
              className={`questions-nav-button ${curr === 0 ? 'nav-button-hidden' : ''}`}
              text="Previous"
              onClick={() => curr > 0 && setCurr(curr - 1)}
            >
              <img src={arrow} className="nav-back-arrow" alt="arrow" />
              <div>Previous</div>
            </button>
            <button
              className={`questions-nav-button ${answers.length < questions.length ? 'nav-button-hidden' : ''}`}
              text="Previous"
              onClick={() => setCurr(curr - 1)}
            >
              <div>See results</div>
            </button>
            <button className="questions-nav-button" text="Next" onClick={() => goToNextQuestion()}>
              <div>Next</div>
              <img src={arrow} className="nav-next-arrow" alt="arrow" />
            </button>
          </nav>
        </div>
      </div>
    </CenteredBox>
  )
}
